import HeroSection from '../../components/HeroSection';
import Features from '../../components/Features/Features';
const Frontpage = () => {
  return (
    <div className='pb-16'>
      <HeroSection
        imageUrl='/images/Klinikken-frontpage.jpg'
        headerText='Helsehuset Vea'
        subHeaderText='Klinikk med fokus på trening og helse'
        buttonText='BLI MEDLEM'
        buttonLink='/bli-medlem'
      ></HeroSection>
      <Features />
    </div>
  );
};

export default Frontpage;
